/**
 * Cart műveletekhez a következő selectorok használhatók:
 *
 *  - .js-cart-add: click-re hozzáadja a terméket a kosárhoz. További opciók:
 *      - data-cart-subproduct-id-selector: Ha csomagot adunk a kosárhoz, akkor a hozzátartozó productId-ket tároló
 *        inputmezők selectorja. Ez alapján lesz elküldve, hogy az adott csomaghoz, milyen termékeket választott be.
 *  - .js-cart-change: quantity input-ra helyezhető, change eventre módosítja a kosárban levő termék mennyiségét.
 *  - .js-cart-minus / .js-cart-plus: click-re növeli a kapcsolódó quantity mező értékét
 *    (Ennek class-a: js-cart-product-quantity-{productId}). Ha a quantity input rendelkezik a js-cart-change classzal, akkor
 *    módosítja a kosárban levő termék mennyiséget is.
 *  - .js-cart-remove: törli a terméket, és az elem parentjét (.js-cart-row-{productId}), ami tartalmazza a terméket.
 *  - .js-cart-add-coupon: click-re hozzáadja a data-cart-coupon-field-selector attribútumban definiált inputban
 *    levő kupont a cart-hoz.
 *
 *
 *  A fenti selectorokat tartalmazó elemek data attribútumai:
 *  - data-cart-product-id: A kapcsolódó product ID. Kötelező megadni!
 *  - data-cart-quantity-field-selector: opcionális, a mennyiséget tartalmazó input mező selectorja.
 *  - data-cart-quantity: opcionális, a termék mennyisége (nincs figyelembe véve, ha van selector megadva).
 */
var Cart = (function ($) {
    'use strict';

    var api = {
            init: function () {
                updateTableVisibility();
                initCartToggle();
                bindEvenHandlers();
            },
            /**
             * @param {Date} date
             *
             * @returns {Boolean}
             */
            isModifiedSince: function (date) {
                var lastmod = new Date(parseInt(window.localStorage.getItem(STORAGE_LASTMOD), 10));

                if (!lastmod || isNaN(lastmod.getTime())) {
                    return false;
                }

                return lastmod.getTime() > date.getTime();
            }
        },
        /**
         * @var {XMLHttpRequest}
         */
        currentXhr,
        updateTimerId,
        MIN_QUANTITY = 1,
        MAX_QUANTITY = 100000,
        CHANGE_THROTTLE = 200,
        STORAGE_LASTMOD = 'cart_lastmod',
        IS_WHOLESALE = false,
        STATE = 'closed';

    function bindEvenHandlers() {
        var $body = $('body');
        var touchedTriggered = false;
        // $body.on('touchstart', '.js-cart-add', function (event) {
        //     touchedTriggered = true;
        //     handleAdd(event);
        // });
        // $body.on('touchstart', '.js-cart-add-on-checkout', function (event) {
        //     touchedTriggered = true;
        //     handleAddOnCheckout(event);
        // });
        $body.on('click', '.js-cart-add', function (event) {
            if (!touchedTriggered) {
                handleAdd(event);
            } else {
                touchedTriggered = false;
            }
        });

        $body.on('click', '.js-cart-add-on-checkout', function (event) {
            if (!touchedTriggered) {
                handleAddOnCheckout(event);
            } else {
                touchedTriggered = false;
            }
        });


        $body.on('click', '.js-cart-remove', handleRemove);
        $body.on('click', '.js-cart-add-coupon', handleAddCoupon);
        $body.on('click', '.js-cart-clear-coupon', handleClearCoupon);

        $body.on('change', '.js-cart-change', handleQuantityChange);
        $body.on('change', '.js-cart-add-input', handleAddQuantityChange);

        $body.on('click', '.js-cart-minus', handleMinusEvent);
        $body.on('click', '.js-cart-plus', handlePlusEvent);

        $body.on('click', '.js-cart-minus-add', handleMinusAddEvent);
        $body.on('click', '.js-cart-plus-add', handlePlusAddEvent);


    }

    function hideCart() {
        $('#cart-sidebar .cart-list').removeClass('active');
        setTimeout(function () {
            $('#cart-sidebar').removeClass('active');
            $('#cart-sidebar').find('.cart-btn a.button').removeClass('hovered');
            $('body').css({'overflow': 'initial'});
        }, 300);
        STATE = 'closed';
    }
    function showCart(animated) {
        animated = animated || false;
        $('body').css({'overflow': 'hidden'});
        $('#cart-sidebar').addClass('active');
        if (animated) {
            $('#cart-sidebar .cart-list').addClass('animated');
        } else {
            $('#cart-sidebar .cart-list').removeClass('animated');
        }
        $('#cart-sidebar .cart-list').addClass('active');
        $('#cart-sidebar').find('.cart-btn a.button').addClass('hovered');
        STATE = 'opened';
    }

    function initCartToggle() {
        console.log('INIT TOGGLE');
        $("body").on('click', '#cart', function (e) {
            console.log('Click TOGGLE');
            if (STATE === 'closed') {
                if (hasItem()){
                    showCart(true);
                } else {
                    swal(wwdh.t('A kosara üres!'), null, 'info');
                }
            } else {
                hideCart();
            }
        });
        $("body").on('keyup', function(e){
            console.log('KEYUP TOGGLE');
            var code = e.keyCode || e.which;
            if (code === 27 && STATE === 'opened'){
                hideCart();
            }
        });
        $("body").on('click', '#cart-sidebar', function(e) {
            console.log('Click Sidebar');
            var elem = $(e.target);
            if (elem.parents('.cart-list').length === 0 || elem.parents('.cart-hide').length > 0 || elem.hasClass('cart-hide')){
                hideCart();
            }
        });
    }

    function hasItem()
    {
        return $('#cart-sidebar .cart-item-product').length > 0;
    }

    function handleAdd(event) {

        var productId = $(event.target).data('cart-product-id'),
            params = buildProductParamsByEventTarget($(event.target));

        //alert(productId);
        updateQuantityFields(productId, params.quantity);
        updateCart('add', params, function () {
            if (!IS_WHOLESALE) {
                showCart(true);
            }
        });
    }


    function handleAddOnCheckout(event) {
        var productId = $(event.target).data('cart-product-id'),
            params = buildProductParamsByEventTarget($(event.target));
        updateQuantityFields(productId, params.quantity);
        params.onCheckout = 1;
        updateCart('add', params, function () {
            //displayMessages('success', [wwdh.t('A termék sikeresen hozzáadva a kosárhoz!')],[wwdh.t('Extra kedvezmény')]);
        });
    }

    function handleAddCoupon() {
        window.location = '/cart/default/index?coupon-redeem=' + $($(this).data('cart-coupon-field-selector')).val();
        // updateCart('addCoupon', {
        //     couponCode: $($(this).data('cart-coupon-field-selector')).val()
        // }, function (result) {
        //     if (result) {
        //         $('#coupon-code-form').hide();
        //     }
        // });
    }

    function handleClearCoupon() {
        updateCart('clearCoupon', {}, function () {
            $('#coupon-code-form').show();
        });
    }

    function handleRemove() {
        var $target = $(this);

        updateCart('remove', buildProductParamsByEventTarget($target), function () {
            removeProductRow($target);
        });
    }

    function removeProductRow($target) {
        var $removableRows,
            $parentRow;

        if ($target.parents('.stacktable').length && !$target.parents('.js-cart-row').length) {
            $parentRow = $target.parents('tr:eq(0)');
            $removableRows = $parentRow;

            $parentRow.prevAll().each(function (i, row) {
                $removableRows = $removableRows.add(row);

                // Az első spacer után megállunk, megvan minden sor az adott termékhez.
                if ($(row).hasClass('st-space')) {
                    return false;
                }
            });
        } else {
            $removableRows = $('.js-cart-row-' + $target.data('cart-product-id'));
        }

        $removableRows.fadeOut(function () {
            $(this).remove();

            updateTableVisibility();
        });
    }

    function handleQuantityChange() {
        var productId = $(this).data('cart-product-id');
        updateQuantityFields(productId, $(this).val());
        updateCart('change', buildProductParamsByEventTarget($(this)));
    }

    function handleAddQuantityChange() {
        $(this).val(parseQuantity($(this).val()));
    }

    function handlePlusEvent(e) {
        e.preventDefault();
        updateQuantity($(this).data('cart-product-id'), 1);
    }

    function handleMinusEvent(e) {
        e.preventDefault();
        updateQuantity($(this).data('cart-product-id'), -1);
    }

    function handlePlusAddEvent(e) {
        e.preventDefault();
        updateQuantityAdd($(this).data('cart-product-id'), 1);
    }

    function handleMinusAddEvent(e) {
        e.preventDefault();
        updateQuantityAdd($(this).data('cart-product-id'), -1);
    }

    function updateQuantity(productId, offset) {
        var $quantityFields = $('.js-cart-product-quantity-' + productId),
            quantity = parseQuantity(parseInt($quantityFields.val(), 10) + offset);

        updateQuantityFields(productId, quantity);

        if ($quantityFields.hasClass('js-cart-change')) {
            clearTimeout(updateTimerId);

            updateTimerId = setTimeout(function () {
                updateCart('change', buildProductParams(productId, quantity));
            }, CHANGE_THROTTLE);
        }
    }

    function updateQuantityAdd(productId, offset) {
        var $quantityFields = $('.js-cart-product-quantity-add-' + productId),
            quantity = parseQuantity(parseInt($quantityFields.val(), 10) + offset);
        updateQuantityFieldsAdd(productId, quantity);
    }

    /**
     * Frissíti a termékhez tartozó összes quantity mezőt (mivel a stackable() többet hoz létre).
     *
     * @param {Number} productId
     * @param {Number} quantity
     */
    function updateQuantityFields(productId, quantity) {
        $('.js-cart-product-quantity-' + productId).val(parseQuantity(quantity));
    }

    /**
     * Frissíti a termékhez tartozó összes quantity mezőt (mivel a stackable() többet hoz létre).
     *
     * @param {Number} productId
     * @param {Number} quantity
     */
    function updateQuantityFieldsAdd(productId, quantity) {
        $('.js-cart-product-quantity-add-' + productId).val(parseQuantity(quantity));
    }

    /**
     * @param {mixed} quantity
     *
     * @return {Number}
     */
    function parseQuantity(quantity) {
        quantity = parseInt(quantity, 10);

        if (isNaN(quantity)) {
            return 1;
        }

        if (quantity < MIN_QUANTITY) {
            return MIN_QUANTITY;
        } else if (quantity > MAX_QUANTITY) {
            return MAX_QUANTITY;
        }

        return quantity;
    }

    function buildProductParamsByEventTarget($target) {
        var quantityField = $target.data('cart-quantity-field-selector'),
            subProductIds = $($target.data('cart-subproduct-id-selector')).map(function () {
                return this.value;
            }).get(),
            quantity;

        if (quantityField) {
            quantity = $(quantityField).val();
        } else if ($target[0].tagName.toLowerCase() === 'input') {
            quantity = $target.val();
        } else {
            quantity = $target.data('cart-quantity');
        }

        return buildProductParams($target.data('cart-product-id'), quantity, subProductIds);
    }

    /**
     *
     * @param {String} productId
     * @param {Number} quantity
     * @param {Array} subProductIds [optional]
     */
    function buildProductParams(productId, quantity, subProductIds) {
        if (subProductIds && subProductIds.length) {
            productId += '-' + subProductIds.join('-');
        }

        return {
            cartProductId: productId,
            quantity: parseQuantity(quantity)
        };
    }

    /**
     * @param {String} action
     * @param {Object} parameters
     * @param {Function} callback [optional]
     */
    function updateCart(action, parameters, callback) {
        //alert('updateCart');
        if (currentXhr && currentXhr.readyState !== 4) {
            currentXhr.abort();
        }

        window.localStorage.setItem(STORAGE_LASTMOD, '' + new Date().getTime());
        //alert('setStorage');
        currentXhr = $.ajax({
            url: '/cart/default/update',
            data: {
                cartParameters: $.extend(parameters, {
                    action: action
                })
            },
            method: 'POST',
            beforeSend: function () {
                $('#cart').addClass('updating');
                $('#cart-add-result').html('');
            },
            success: function (response) {
                //alert('ajaxSuccess');
                if (!response.success) {
                    if (response.errorMessages.length) {
                        displayMessages('error', response.errorMessages, response.languageItems.error,response.buttonText, response.redirectUrl);
                    } else if(response.warningMessages.length){
                        displayMessages('warning', response.warningMessages, response.languageItems.warning, response.languageItems.warning_button_text, '');
                    }
                    resetQuantitiesToPrev(parameters.cartProductId);
                } else {
                    setPrevQuantities(parameters.cartProductId);
                }

                displayMessages('success', response.successMessages, response.languageItems.cart_updated);
                updateHtmlElements(response.htmlElements);
                IS_WHOLESALE = response.isWholesale;
                updateProducts(response.products);
                updateTableVisibility();
                if (response.discountAlert) {
                    $('body').trigger('choose-discount-type');
                }

                if (callback) {
                    callback();
                }
            },
            error: function (response) {
                if (response.statusText !== 'abort') {
                    swal({
                        title: wwdh.t('Sajnáljuk, valami hiba történt!'),
                        text: wwdh.t('Kérjük, frissítsd az oldalt, és próbáld újra!'),
                        type: "error",
                        confirmButtonText: wwdh.t('Frissítés')
                    },function(){
                        location.reload();
                    });
                }
            }
        });
    }

    function resetQuantitiesToPrev(cartProductId) {
        $('[data-cart-product-id="' + cartProductId + '"][data-prev-quantity]').each(function (k,v) {
            $(v).val($(v).data('prev-quantity'));
        })
    }

    function getPrevQuantities(cartProductId) {
        return $('[data-cart-product-id="' + cartProductId + '"][data-prev-quantity]').data('prev-quantity');
    }
    function setPrevQuantities(cartProductId) {
        $('[data-cart-product-id="' + cartProductId + '"][data-prev-quantity]').each(function (k, v) {
            $(v).data('prev-quantity', $(v).val());
        })
    }

    /**
     * @param {Object} elements
     */
    function updateHtmlElements(elements) {
        $.each(elements, function (id, value) {
            var $updatedElement = $('#' + id);

            if (new RegExp('id\s*=\s*[\'"]' + id + '[\'"]', 'i').test(value)) {
                $updatedElement.replaceWith(value);
                if (id === 'cart-sidebar' && STATE === 'opened') {
                    if (hasItem()){
                        showCart(false);
                    } else {
                        hideCart();
                    }
                }
            } else if ($updatedElement.length && $updatedElement[0].tagName.toLowerCase() === 'input') {
            } else {
                $updatedElement.html(value);
            }
        });
    }

    /**
     *
     * @param {Array} products
     */
    function updateProducts(products) {
        var i,
            product;

        for (i = 0; i < products.length; ++i) {
            product = products[i];

            $.each(product, function (property, value) {
                var $updatedElement = $('.js-cart-product-' + property + '-' + product.id);

                if ($updatedElement.length && $updatedElement[0].tagName.toLowerCase() === 'input') {
                } else {
                    $updatedElement.html(value);
                }
            });
        }
    }

    function updateTableVisibility() {
        if ($('#cart').data('item-count')) {
            $('.js-cart-notempty').show();
            $('.js-cart-empty').hide();
        } else {
            $('.js-cart-notempty').hide();
            $('.js-cart-empty').show();
        }
    }


    /**
     * @param {String} type
     * @param {Array} messages
     * @param {String} title
     * @param {String} confirmButtonText
     */
    function displayMessages(type, messages, title, confirmButtonText, redirectUrl) {
        if (!messages.length) {
            return;
        }
        if (!confirmButtonText) {
            confirmButtonText = 'OK';
        }

        swal({
            html: true,
            title: title,
            text: messages.join('<br>'),
            type: type,
            confirmButtonText: confirmButtonText,
        },function(){
            if (redirectUrl != ''){
                console.log(redirectUrl);
                location.href = redirectUrl;
            }
        });
    }

    return api;
})(jQuery);

jQuery(function () {
    Cart.init();
});